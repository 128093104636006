import DatePicker from 'react-datepicker'
import {TextArea, Form, Button, Dropdown, Label, Grid, Segment, Icon, Accordion} from "semantic-ui-react";

import 'react-datepicker/dist/react-datepicker.css';

import React from "react";
import moment from 'moment';


export default class KDatePicker extends React.Component {
    // static propTypes = {
    //     input: PropTypes.shape({
    //         onChange: PropTypes.func,
    //         value: PropTypes.object,
    //     }).isRequired,
    //     meta: PropTypes.shape({
    //         touched: PropTypes.bool,
    //         error: PropTypes.object,
    //     }).isRequired,
    //     inputValueFormat: PropTypes.string,
    // };

    static defaultProps = {
        inputValueFormat: null,
    };

    state = {
        selectedDate: null
    };

    componentWillMount() {
        if (this.props.input.value) {
            this.setState({
                selectedDate: moment(this.props.input.value, 'YYYY.MM.DD'),
            });
        }
    }

    handleChange = (date) => {
        this.setState({
            selectedDate: date,
        });

        this.props.input.onChange(date);
    }

    handleBlur = (date)  => {
        this.props.input.onBlur(date);
    }

    render() {
        const {
            meta: { touched, error },
            ...rest
        } = this.props;

        return ( <React.Fragment>
                 <Form.Input {...rest} control={DatePicker}
                                dateFormat="YYYY.MM.DD"
                                selected={this.state.selectedDate}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                minDate={rest.minDate} label={rest.label} showYearDropdown={true} />
                {touched && error && <Label basic color='red' pointing>{error}</Label>}
            </React.Fragment>

        );
    }
}


