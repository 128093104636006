import { connect } from 'react-redux'
import LicenseAdd from '../../components/License/LicenseAdd'
import {initializeLicenseWizard} from "../../actions";


const mapStateToProps = (state, ownProps) => {
    return {
        // request: state.license.request,
        // expireDate: state.license.expireDate
        //initialValues: state.license
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LicenseAdd);

