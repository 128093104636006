import React, { Component } from 'react'
import { Header, Icon , Button, Divider, Modal, Form, TextArea} from 'semantic-ui-react'

export default class LicenseKey extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.setState({
            canSave: false,
            open: false
        })

    }

    onClose = () => {
        this.setState({open: false})
    }

    onOpen = () => {
        this.setState({open: true})
        return this.props.getLicenseKey(this.props.rowId)
    }

    render() {
        const {licenseId, licenseKey, machineId} = this.props;
        const {open} = this.state;
        return (
            <Modal size='tiny' open={open} dimmer='inverted' trigger={<Icon name='key' size='large' onClick={this.onOpen}/>} >
                <Modal.Header>
                    <Header as='h4'>
                        <Icon.Group size='large'>
                            <Icon name='key'/>
                        </Icon.Group>
                        Registered license key
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>License id:</label>
                            <input value={licenseId} readOnly={true}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Machine id:</label>
                            <input value={machineId} readOnly={true}/>
                        </Form.Field>


                        <Form.Field control={TextArea} label='License key:' value={licenseKey} rows={4} autoHeight style={{fontFamily: 'Monospace'}}/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.onClose}>
                        <Icon name='user cancel' />Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
