import React, { Component } from 'react'
import { Header, Icon , Button, Divider, Modal} from 'semantic-ui-react'
import UserForm from './UserForm'

export default class UserEdit extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.setState({
            open : false,
            canSave : false
        });
    }

    onClose = () => {
        this.setState(
            { open: false }
        )
    }

    onOpen = () => {
        this.setState(
            { open: true }
        )
    }

    onClick = ()  => {
        this.refs.editForm.submit(this.props.onSubmit) // Submit form values
        this.setState(
            { open: false }
        )
    }


    onSave = (values) => {
        console.log(this.props);
        this.props.updateUser({
            id: this.props.rowId,
            email: values.email_user,
            company: values.company,
            expire_date: values.expire_date,
            license_type: values.license_type,
            old_license_type: this.props.data.license_type,
            old_expire_date: this.props.data.expire_date,
            fullname: values.fullname,
            password: values.password

        })

   }


    updateValidationBtnState = (canSave) => {
        this.setState({
            canSave: canSave
        })
    };

    render() {
        const {userId, data} = this.props;
        const {open, canSave} = this.state;

        return (
            <Modal size='small' open={open} dimmer='inverted'
                   trigger={<Icon name='write' size='large' onClick={this.onOpen}/>} >
                <Modal.Header>
                    <Header as='h4'>
                        <Icon.Group size='large'>
                            <Icon name='user'/>
                            <Icon corner name='write'/>
                        </Icon.Group>
                        Update user data for {userId}
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    <UserForm ref='editForm' onSubmit={this.onSave.bind(this)}
                              updateValidationState={this.updateValidationBtnState} initialValues={data}/>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.onClick.bind(this)} disabled={canSave}>
                        <Icon name='write'/>Update user
                    </Button>
                    <Button onClick={() => {this.onClose()}}>
                        <Icon name='user cancel' />Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}


