import React, { Component } from 'react'
import 'react-tippy/dist/tippy.css'
import LicenseWizard from "./LicenseWizard";

import { Header, Divider, Icon, Popup, Button } from 'semantic-ui-react'
import {Tooltip} from 'react-tippy'

import ReactTable from 'react-table'
import "react-table/react-table.css";
import {getLicenseId, getLicenseName} from '../../common/licenseHelper'
import LicenseDel from '../../containers/License/LicenseDel'
import LicenseAdd from "./LicenseAdd";
import LicenseKey from "../../containers/License/LicenseKey";
import LicenseReq from "../../containers/License/LicenseReq";

import moment from "moment/moment";
import matchSorter from "match-sorter";


let columns = [
    {
        Header: "Full name",
        accessor: "fullname"
    },

    {
        Header: "Auth email",
        accessor: "email",
    },

    {
        Header: "License id",
        accessor: "license_name",
        minWidth: 100,
        Cell: ({row,value}) => (
               <Tooltip title={value} interactive={true} theme='light' animateFill={true} >
                   {value}
               </Tooltip>
            ),
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["license_name"]}),
        filterAll: true


    },
    {
        Header: "Company",
        accessor: "company",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["company"]}),
        filterAll: true

    },
    {
        Header: "Create date",
        id: "create_date",
        //accessor: d => moment(d.create_date, "DD.MM.YYYY").format("YYYY.MM.DD"),
        //accessor: "create_date",
        accessor: (row) => moment(row.create_date, "DD.MM.YYYY HH:mm:ss").format("YYYY.MM.DD HH:mm:ss").toString(),
        // sortMethod: (a, b, desc) => {
        //     return 0
        //
        // },
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["create_date"], threshold: matchSorter.rankings.CONTAINS}),
        filterAll: true,
        // Cell: ({row, value}) => (
        //     <span>{moment(value, "DD.MM.YYYY").format("YYYY.MM.DD").toString()}</span>
        // ),
        width: 150
    },

    {
        Header: "Expire date",
        id: "expire_date",
        accessor: (row) => moment(row.expire_date, "DD.MM.YYYY").format("YYYY.MM.DD").toString(),

        // Cell: ({row, value}) => (
        //     <span>{moment(value, "DD.MM.YYYY").format("YYYY.MM.DD")}</span>
        // ),
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["expire_date"], threshold: matchSorter.rankings.CONTAINS}),
        filterAll: true,
        width:100,
    },

    {
        Header: "Origin",
        accessor: "origin",
        width:80
    },

    {
        Header: "License Code",
        id: "license_type",
        accessor: d => getLicenseName(d.license_type),
        width: 80

    },

    {
        Header: "Action",
        accessor: "license_format",
        width:130,
        Filter: () => <React.Fragment></React.Fragment>,
        Cell: ({row, original, value}) => (
            <React.Fragment>
                <LicenseDel rowId={original.id} licenseId={row.license_name}/>
                <LicenseKey rowId={original.id} licenseId={row.license_name}/>
                <LicenseReq rowId={original.id} licenseId={row.license_name}/>
                {row.license_format == true ?
                    <Popup trigger={<Icon name='exclamation triangle' size='large'/>} content='Legacy license format' inverted></Popup> : null}
            </React.Fragment>
        )

    }

];


export default class LicenseList extends Component {
    constructor(props) {
        super(props);
    }


    componentWillMount() {
        this.setState({
            open : false
        });
    }



    show = () =>  {
        this.setState({ open: true })
        return this.props.initLicenseWizard();
    }

    close = () => this.setState({ open: false })

    render() {
        const {data, isAdmin} = this.props;
        const {open} = this.state;
        console.log(data);
        return (
            <React.Fragment>
                <Header as='h2'>
                    <Icon name='user'/>
                    <Header.Content>
                        License Management
                    </Header.Content>
                </Header>
                <Button compact onClick={this.show} disabled={!isAdmin}>
                    <Header as='h4'>
                        <Icon.Group size='large'>
                            <Icon name='key'/>
                            <Icon corner name='plus'/>
                        </Icon.Group>
                        Add new license
                    </Header>
                </Button>
                <Divider hidden/>

                <LicenseAdd open={open} onClose={this.close} page={1}/>

                <ReactTable
                    filterable
                    data={data}
                    columns={columns}
                    defaultSorted={[
                        {
                            id: "create_date",
                            desc:true
                        }
                    ]}
                    defaultPageSize={20}
                    className="-striped -highlight"/>
            </React.Fragment>
    )
    }

}

