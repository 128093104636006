import { connect } from 'react-redux'
import LicenseReq from '../../components/License/LicenseReq'
import {getLicenseReq} from '../../actions'

const mapStateToProps = state => {
    return {
        licenseReq: state.license.activeLicense.licenseReq,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLicenseReq: (id) => {
            return dispatch(getLicenseReq(id));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(LicenseReq);









