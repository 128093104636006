import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import moment from 'moment';

// License state
const initialState = {
    request: '',
    expire_date: moment().add(1, 'Y'),
    productCodes: [],
    license_types: [],
    licenseKey : '',
    licenseRequest: '',
    licenseHolder : '',
    fullName: '',
    email: '',
    companyName: '',
    computerId : '',
    allLicenses : [],
    currentPage : 1,
    activeLicense : {
        licenseKey: '',
        licenseReq: '',
        machineId:''
    }
};

// Authentication state
const authInitialState = {
    username: 'Jon Doe',
    adminInfo: 0,
    isLoggedIn: false
};


const usersInitialState = {
    allUsers : []
};


const handleUsersActions = (state = usersInitialState, action) => {
    switch (action.type) {
        case 'STORE_USERS':
            return {
                ...state, allUsers: action.payload.allUsers
            };
        default:
            return state
    }
    return state;
}




const handleAuthenticationActions = (state = authInitialState, action) => {
    switch (action.type) {
        case 'AUTH_USER':
            return {
                ...state,
                username: action.payload.username,
                isLoggedIn: true
            }
        case 'LOG_OUT': {
            return {
                ...state,
                isLoggedIn: false,
                adminInfo: 0
            }
        }

        case 'STORE_ADMIN_INFO': {
            return {
                ...state,
                adminInfo: action.payload.admin,
            }
        }


        default:
            return state
    }
    return state;
}


const handleLicenceActions = (state = initialState, action) => {
    console.log(action);
    console.log(state)
    switch (action.type) {
        case 'INIT_LICENSE_WIZARD':
            return {
                ...state, expire_date: action.payload.expire_date,
                license_types: action.payload.license_types,
                license_format: true,
                licenseRequest: '',
                fullName: '',
                email: '',
                companyName: '',
                currentPage: 1

            }
        case 'UPDATE_PRODUCT_CODES': return {
            ...state,
            productCodes: action.payload
        }
        case 'TOGGLE_LICENSE_TYPES':
            return {
                ...state, license_format: !state.license_format
            }
        case 'STORE_LICENSES':
           return {
               ...state, allLicenses: action.payload.allLicenses
           }
       case 'NEXT_LICENSE_PAGE':
           return {
               ...state, currentPage: state.currentPage + 1
           }
       case 'PREVIOUS_LICENSE_PAGE':
            return {
                ...state, currentPage: state.currentPage - 1
            }

       case 'UPDATE_EXPIRE_DATE':
            return {
                ...state,
                expire_date: action.payload
            }
        case 'UPDATE_ACTIVE_LICENSE_KEY':
            return {
                ...state,
                activeLicense : {
                    licenseKey: action.payload.licenseKey,
                    machineId: action.payload.machineId,
                    licenseReq: state.activeLicense.licenseReq
                }
            }
        case 'UPDATE_ACTIVE_LICENSE_REQ':
            return {
                ...state, activeLicense: {
                    licenseKey: state.activeLicense.licenseKey,
                    machineId: state.activeLicense.machineId,
                    licenseReq: action.payload.licenseReq
                }
            }

        case 'UPDATE_LICENSE_REQUEST':
            return {
                ...state,
                licenseKey: action.payload.licenseKey,
                licenseHolder: action.payload.licenseHolder,
                licenseRequest: action.payload.licenseRequest,
                computerId: action.payload.computerId,
                license_types: action.payload.license_types,
                expire_date: action.payload.expire_date,
                fullName: action.payload.fullName,
                email: action.payload.email,
                companyName: action.payload.companyName,
                licenseFormat: action.payload.licenseFormat
            };
        default:
            return state

    }
};

const LicensePortalApp = combineReducers({
    license: handleLicenceActions,
    auth: handleAuthenticationActions,
    users: handleUsersActions,
    form: formReducer
});

export default LicensePortalApp;
