import { connect } from 'react-redux'
import Logout from '../../components/Login/Logout'
import {getAdminInfo, logInUser, logOutUser} from '../../actions/index'


const mapStateToProps = state => {
    return {
        userName: state.auth.username
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logOutUser : () => {
            return dispatch(logOutUser());
        }

    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(Logout);
