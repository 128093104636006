import { connect } from 'react-redux'
import UserEdit from '../../components/User/UserEdit'
import {updateUser} from "../../actions/index";


const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateUser: (data) => {
            return dispatch(updateUser(data));
        }
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(UserEdit);
