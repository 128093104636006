import React, { Component } from 'react'
import LicenseInfo from '../../containers/License/LicenseInfo'
import LicenseReview from '../../containers/License/LicenseReview'
import LicenseConfirm from '../../containers/License/LicenseConfirm'
import { Icon, Step, Grid, Header, Divider } from 'semantic-ui-react'

class LicenseWizard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { page, initialValues, previousPage, nextPage, onClose} = this.props;
        console.log(initialValues)

        return (
            <div>
                <Grid centered={true}>
                    <Grid.Row stretched>
                        <Grid.Column width={16}>
                            <Step.Group >
                                <Step active={page === 1}>
                                    <Icon name='user'/>
                                    <Step.Content>
                                        <Step.Title>Collect</Step.Title>
                                        <Step.Description>Collect User Information</Step.Description>
                                    </Step.Content>
                                </Step>

                                <Step active={page === 2}>
                                    <Icon name='content'/>
                                    <Step.Content>
                                        <Step.Title>Review</Step.Title>
                                        <Step.Description>Review License Certificate</Step.Description>
                                    </Step.Content>
                                </Step>

                                <Step active={page === 3}>
                                    <Icon name='info'/>
                                    <Step.Content>
                                        <Step.Title>Confirm</Step.Title>
                                        <Step.Description>Confirm Certificate</Step.Description>
                                    </Step.Content>
                                </Step>
                            </Step.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row >
                        <Grid.Column width={10} stretched={true}>
                            { page === 1 && <LicenseInfo initialValues={initialValues}/> }
                            { page === 2 && <LicenseReview previousPage={previousPage} nextPage={nextPage}/> }
                            { page === 3 && <LicenseConfirm previousPage={previousPage} onClose={onClose} /> }

                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </div>
        )
    }
}


export default LicenseWizard;



