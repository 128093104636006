import React, { Component } from 'react'
import LicenseAdd from "../../containers/License/LicenseAdd";
import LicenseList from '../../containers/License/LicenseList'




class LicenseManagement extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getAllLicenses();
    }

    render() {
        return (
            <React.Fragment>
                {{ // Mimic switch
                    listLicenses: (<LicenseList data={this.props.data}/>),
                    addLicense: (<LicenseAdd/>)
                }[this.props.type]}
            </React.Fragment>
        )
    }
}

export default LicenseManagement