import React, { Component } from 'react';
import {Header, Icon, Container, Grid, Tab, TextArea, Form, Menu, Dropdown, Label, Divider} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import LicenseWizard  from './License/LicenseWizard'
import LicenseList from './License/LicenseList'

import UserManagement from "../containers/User/UserManagement";
import LicenseManagement from "../containers/License/LicenseManagement";

/*
const panes = [
    { menuItem: 'Register new license' , render: () => <Tab.Pane attached={false}><LicenseWizard /> </Tab.Pane>},
    { menuItem: 'View registered licenses' , render: () => <Tab.Pane attached={false}><LicenseList/></Tab.Pane>},
    { menuItem: 'View registered users' , render: () => <Tab.Pane attached={false}><LicenseList/></Tab.Pane>},
]
*/


export default class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { activeItem: 'listLicenses', isUserManagement: false, admin_info: 0}
    }

    componentWillMount() {
        if (!this.props.isLoggedIn) this.props.history.push('/login')
    }
    componentWillReceiveProps() {
        if (!this.props.isLoggedIn) this.props.history.push('/login')
    }

    getCategory(name) {
        var categories = ['listUsers', 'addUser', 'removeUser'];
        return categories.includes(name);
    }
    handleItemClick = (e, { name }) => this.setState({
        activeItem: name,
        isUserManagement: this.getCategory(name)
    })



    render() {
        const {activeItem, isUserManagement} = this.state;
        console.log(this.props)
        const access_level = !this.props.isAdmin ? 'Regular user' : 'Admin';
        return (
            <React.Fragment>
                <Menu>
                    <Menu.Item header>
                        <Icon name='key'/>
                        Kelda License Management
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Icon name='settings'/>
                        </Menu.Item>
                        <Dropdown item text={`${this.props.userName} (${access_level})`}>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <Link to='/logout'>{`logout`}</Link>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu>
                        <Menu vertical floated>
                            {/*             <Menu.Item>
                                        <Menu.Header>
                                    <Icon name='home'/>
                                    Main
                                    </Menu.Header>
                                </Menu.Item>
                            */}
                            <Menu.Item>
                                <Menu.Header name='Users'>
                                    <Icon name='user'/>
                                    Users
                                </Menu.Header>
                                <Menu.Menu>
                                    <Menu.Item name='listUsers' active={activeItem === 'listUsers'} onClick={this.handleItemClick}>
                                        All users
                                    </Menu.Item>
                            {/*        <Menu.Item name='addUser' active={activeItem === 'addUser'} onClick={this.handleItemClick}>
                                        Add User
                                    </Menu.Item>
                                    <Menu.Item name='removeUser' active={activeItem === 'removeUser'} onClick={this.handleItemClick}>
                                        Remove User
                                    </Menu.Item>*/}
                                </Menu.Menu>
                            </Menu.Item>
                             <Menu.Item>
                                 <Menu.Header name='Licenses'>
                                    <Icon name='key'/>
                                     Offline Licenses
                                 </Menu.Header>
                                 <Menu.Menu>
                                     <Menu.Item name='listLicenses' active={activeItem === 'listLicenses'} onClick={this.handleItemClick}>
                                         All licences
                                     </Menu.Item>
 {/*                                    <Menu.Item name='registerLicense' active={activeItem === 'registerLicense'} onClick={this.handleItemClick}>
                                         Register License
                                     </Menu.Item>
 */}                                </Menu.Menu>
                             </Menu.Item>
                            <Menu.Item>
                                <Menu.Header name='Enterprise'>
                                    <Icon name='settings'/>
                                    Enterprise Management
                                </Menu.Header>
                            </Menu.Item>
                        </Menu>
                    <Grid >
                    <Grid.Row >

                        <Grid.Column width={16} floated='right'>
                                {isUserManagement ? (<UserManagement type={activeItem}/>) : <LicenseManagement type={activeItem}/> }
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </React.Fragment>
        )
    }
};
