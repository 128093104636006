import {change, reduxForm} from 'redux-form'
import { connect } from 'react-redux'
import LicenseInfo from '../../components/License/LicenseInfo'
import {signLicenseRequest, nextLicensePage, toggleLicenseTypes} from "../../actions/index";


const mapStateToProps = (state, ownProps) => {
    return {
        // request: state.license.request,
        // expireDate: state.license.expireDate
        initialValues: state.license,
        productCodes: state.license.productCodes
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        signLicense: (expireDate, licenseTypes, licenseRequest, fullname, email, companyName) => {
          return dispatch(signLicenseRequest(expireDate, licenseTypes, licenseRequest, fullname, email, companyName))
        },

        toggleLicenseTypes: () => {
            return dispatch(toggleLicenseTypes())
        },
        updateLicenseRequest : (request) => {
            dispatch(change("license", "licenseHolder", request.licenseHolder))
            dispatch(change("license", "licenseCertificate", request.licenseCertificate))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LicenseInfo);
