import { connect } from 'react-redux'
import LoginReset from '../../components/Login/LoginReset'
import {resetAccount} from '../../actions/index'


const mapStateToProps = state => {
    return {
        userName: state.auth.username
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetAccount : (email) => {
            return dispatch(resetAccount(email));
        }

    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(LoginReset);
