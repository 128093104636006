import { connect } from 'react-redux'
import UserManagement from '../../components/User/UserManagement'
import {getAdminInfo, getAllUsers} from "../../actions/index";

const mapStateToProps = state => {
    return {
        userName: state.auth.username,
        isAdmin: state.auth.adminInfo === 1,
        data: state.users.allUsers
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAllUsers : () => {
            return dispatch(getAllUsers());
        },


    }

};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(UserManagement);
