import React, {Component} from "react";
import { Header, Icon, Modal, Button} from 'semantic-ui-react'

import LicenseWizard from '../../containers/License/LicenseWizard';

class LicenseAdd extends Component {
    render() {
        const {open, onClose} = this.props;
        return (
            <Modal size='large' open={open} dimmer='inverted' onClose={() => onClose()}>
                <Modal.Header>
                    <Header as='h4'>
                        <Icon.Group size='large'>
                            <Icon name='key'/>
                            <Icon corner name='plus'/>
                        </Icon.Group>
                        Add license
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    <LicenseWizard page={1} onClose={onClose}/>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => onClose()}>
                        <Icon name='user cancel' />Cancel
                    </Button>
                </Modal.Actions>
            </Modal>

        )
    }
}

export default LicenseAdd;