import React, { Component } from 'react'
import { Header, Icon , Button, Divider, Modal} from 'semantic-ui-react'

export default class LicenseDel extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.setState({
            open : false
        });
    }

    onClose = () => {
        this.setState(
            { open: false }
        )
    }

    onOpen = () => {
        this.setState(
            { open: true }
        )
    }

    onDelete = (id)  => {
        return this.props.delLicense(id)
    }
    render() {
        const {rowId, licenseId} = this.props;
        const {open} = this.state;
        return (
            <Modal size='tiny' open={open} dimmer='inverted' trigger={<Icon name='trash' size='large' onClick={this.onOpen}/>} >
                <Modal.Header>
                    <Header as='h4'>
                        <Icon.Group size='large'>
                            <Icon name='key'/>
                            <Icon corner name='trash'/>
                        </Icon.Group>
                        Delete license?
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    Are you sure want delete license {licenseId}?
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => this.onDelete(rowId)}>
                        <Icon name='remove'/>Delete license
                    </Button>
                    <Button onClick={this.onClose}>
                        <Icon name='user cancel' />Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
