import React, { Component } from 'react'
import {Button, Grid, Form, TextArea, Label, Segment} from 'semantic-ui-react'
import { Field, reduxForm} from 'redux-form'

const CustomTextArea = ({label, input, placeHolder, rows, meta : {touched, error}}) =>
    <div>{touched && error && <Label basic color='red' pointing='below'>{error}</Label>}
        <Form.Field {...input} label={label} control={TextArea} placeholder={placeHolder} rows={rows} autoHeight style={{fontFamily: 'Monospace'}} />

    </div>
;


class LicenseReview extends Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (values) => {
        return this.props.nextPage();
    }

    render() {
        const {previousPage, handleSubmit} = this.props;
        console.log(this.props);

        return (
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <Grid padded>
                    <Grid.Row columns={1} centered>
                        <Grid.Column>
                            <Form.Group widths={2}>
                                <Field name='licenseHolder' label='License holder:' component={Form.Input} readOnly={true}/>
                                <Field name='computerId' label='Computer id:' component={Form.Input} readOnly={true} />

                            </Form.Group>
                            <Field name='licenseKey' component={CustomTextArea} label='Generated license certificate:'/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        { this.props.initialValues.licenseFormat ?
                        <Segment inverted color="red">
                            You are using an old license format. Please consider to upgrade your software to a newer version
                        </Segment> : null }
                    </Grid.Row>
                    <Grid.Row columns={1} centered >
                        <Button icon="left arrow" labelPosition="left" content="Previous" type="button" onClick={previousPage}/>
                        <Button icon="right arrow" labelPosition="right" content="Next" />
                    </Grid.Row>
                </Grid>
            </Form>


        )
    }
}


export default reduxForm({
    form: 'licenseReview',
    enableReinitialize : true, // this is needed!!

})(LicenseReview);
