import _ from 'lodash'

/*
const licenseTypesUnsorted = [
    { key: 1, text: 'Leidar DLL', value: 1},
    { key: 2, text: 'Leidar HMI', value: 2},
    { key: 3, text: 'Virtual Leidar', value: 3},
    { key: 9, text: 'Leidar Full', value: 9},
    { key: 10, text: 'Straume DLL', value: 10},
    { key: 11, text: 'Leidar Vault', value: 11},
    { key: 12, text: 'LeidarPlan', value: 12},
    { key: 13, text: 'LeidarRT(HM+)', value: 13},
    { key: 14, text: 'LeidarRT(HM+, ILD)', value: 14},
    { key: 15, text: 'LeidarRT(HM+, ILD, PC)', value: 15},
    { key: 16, text: 'LeidarRT(HM+, ILD, PC, IME)', value: 16},
    { key: 17, text: 'LeidarRT Full', value: 27},
    { key: 18, text: 'KIME', value: 30},
    { key: 90, text: 'Straume Full', value: 90},
    { key: 99, text: 'All packages', value: 99}
];
*/

export let licenseTypes = null;
const setProductCodes = (product_codes) => {
    let unsorted = product_codes.map((x) => (
        {
            key: parseInt(x[0]),
            text: x[1],
            value: parseInt(x[0]),

        }
    ))
    licenseTypes = _.sortBy(unsorted, 'text')
}


const getLicenseTypes = () => {
    return licenseTypes === null? []: licenseTypes;
}


const getLicenseId = (name) => {
    return _.find(getLicenseTypes() , ['t' +
    'ext', name]).value;
}

const getLicenseName = (key) => {
    let licenseTypes1 = getLicenseTypes();
    // console.log(key);
    // console.log(licenseTypes1);
    return _.find(getLicenseTypes(), ['key', key]).text;
}


export {getLicenseId, getLicenseName, setProductCodes};

//export default licenseTypes;
