import { connect } from 'react-redux'
import Menu from '../components/MainMenu'


const mapStateToProps = state => {
    return {
        isLoggedIn : state.auth.isLoggedIn,
        userName: state.auth.username,
        isAdmin: state.auth.adminInfo === 1
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

  }


};

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(Menu);
