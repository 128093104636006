import { connect } from 'react-redux'
import LoginNewPassword from '../../components/Login/LoginNewPassword'
import {newPassword} from '../../actions/index'

const mapStateToProps = state => {
    return {
        userName: state.auth.username
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        newPassword : (confirmToken, email, password, history) => {
            return dispatch(newPassword(confirmToken, email, password, history));
        }

    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(LoginNewPassword);
