import React, { Component } from 'react';
import { Route, BrowserRouter} from 'react-router-dom';


import './App.css';

import Login from './containers/Login/Login';
import Menu from './containers/MainMenu';
import Logout from './containers/Login/Logout'
import LoginReset from './containers/Login/LoginReset'
import LoginNewPassword from "./containers/Login/LoginNewPassword";

class App extends Component {
  render() {
    return (
        <BrowserRouter basename='/'>
        <div>
                <Route exact path="/" component={Menu}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/logout" component={Logout}/>
                <Route exact path="/reset" component={LoginReset}/>
                <Route exact path="/confirm-reset/:token" component={LoginNewPassword}/>
        </div>
        </BrowserRouter>

    );
  }
}

export default App;
