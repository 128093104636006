import React, {Component} from "react";
import { Header, Icon, Divider, Form, Label, Container, Select, Button, Segment, Message} from 'semantic-ui-react'
import KDatePicker from '../KDatePicker'
import KDropDown from '../KDropDown'
import {requiredField, validateEmail} from '../../common/clientValidators'
import {Field, reduxForm, initialize} from 'redux-form'

import moment from "moment/moment";
import {licenseTypes} from "../../common/licenseHelper";

const validate = values => {
    const errors = {}
    if (values.password && values.passwordConfirm) {
        if (values.password !== values.passwordConfirm) errors.password = 'Passwords does not match';
    }
    return errors;
}


const RenderInputField = ({input,  label, meta: { touched, error }, ...rest}) =>
    <Form.Field>
        <label>{label}</label>
        <input {...input} {...rest} />
        {touched && error && <Label  basic color='red' pointing>{error}</Label>}
    </Form.Field>




class UserForm extends Component {
    constructor(props) {
        super(props)

       /* this.props.initialize({
            expireDate: moment().add(1, 'Y'),
            licenseType: 99})*/
    }

    render() {
        const {  error, handleSubmit} = this.props
        return (
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Field name='fullname' label="Full name" control='input' placeholder='Enter full name' component={RenderInputField} width={4} validate={[requiredField]}/>
                    <Field name='email_user' label="Email" control='input' placeholder='Valid email' component={RenderInputField} validate={[requiredField, validateEmail]} width={5}/>
                    <Field name='company' label="Company" control='input' placeholder='Company' component={RenderInputField} width={4} validate={[requiredField]}/>
                </Form.Group>
                <Form.Group>
                    <Field name='expire_date' label="Expire date" component={KDatePicker}
                           minDate={moment()} validate={[requiredField]} placeholder='Expire date' />

                    <Field name='license_type' label="License type" component={KDropDown}
                           search selection options={licenseTypes}
                           placeholder='Select licenses' />

                </Form.Group>
                <Form.Group>
                    <Field name='password' label="Password" component={RenderInputField} type='password'
                           validate={[requiredField]} placeholder='Enter password' />

                    <Field name='passwordConfirm' label="Confirm password" component={RenderInputField} type='password'
                           validate={[requiredField]} placeholder='Confirm password'/>

                </Form.Group>

                {error && <Message negative header='Action forbidden' content={error}/> }

            </Form>
        )

    }


}

export default reduxForm({
    form: 'userForm',
    enableReinitialize : true, // this is needed!!
    validate

})(UserForm);
