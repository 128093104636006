import React from "react";
import {TextArea, Form, Button, Select, Label, Grid, Segment, Icon, Accordion} from "semantic-ui-react";

export default class KDropDown extends React.Component {
    // static propTypes = {
    //     input: PropTypes.shape({
    //         onChange: PropTypes.func,
    //         value: PropTypes.array,
    //     }).isRequired,
    //     meta: PropTypes.shape({
    //         touched: PropTypes.bool,
    //         error: PropTypes.array,
    //     }).isRequired
    // };
    state = {
        selectedItems: [],
    };

    componentWillMount() {
        console.log('componentWillMount')
        console.log(this.props)
        if (this.props.input.value) {
            this.setState({
                selectedItems: this.props.input.value,
            });
        }
    }

    handleChange = (e, items) => {
        this.setState({
            selectedItems: items.value,
        });


        this.props.input.onChange(items.value);
    }

    handleBlur = (e, items)  => {
        this.props.input.onBlur(items.value);
    }


    render() {

        const {
            meta: { touched, error },
            ...rest
        } = this.props;

        console.log(this.props)
        console.log(this.state.selectedItems)
        return (
            <React.Fragment>
                    <Form.Input {...rest} control={Select}
                                value={this.state.selectedItems}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                label={rest.label}/>

            </React.Fragment>
        );
    }
}
