import { connect } from 'react-redux'
import LicenseList from '../../components/License/LicenseList'
import {initializeLicenseWizard} from "../../actions";


const mapStateToProps = (state, ownProps) => {
    return {
        // request: state.license.request,
        // expireDate: state.license.expireDate
        //initialValues: state.license
        isAdmin: state.auth.adminInfo === 1
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initLicenseWizard : () => {
            return dispatch(initializeLicenseWizard());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LicenseList);

