import { connect } from 'react-redux'
import UserDel from '../../components/User/UserDel'
import {delUser} from "../../actions/index";


const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        delUser: (email) => {
            return dispatch(delUser(email));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(UserDel);
