import moment from "moment/moment";
import React, { Component } from 'react'
import matchSorter from 'match-sorter'
import { Header, Icon , Button, Divider, Modal} from 'semantic-ui-react'

import {getLicenseId, getLicenseName} from '../../common/licenseHelper'
import ReactTable from 'react-table'

import UserAdd from '../../containers/User/UserAdd';
import UserEdit from '../../containers/User/UserEdit';
import UserDel from '../../containers/User/UserDel';


class UserTable extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                Header: "Full name",
                accessor: "fullname",
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["fullname"]}),
                filterAll: true


            },
            {
                Header: "Email",
                accessor: "email_user",
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["email_user"]}),
                filterAll: true

            },
            {
                Header: "Company",
                accessor: "company",
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["company"]}),
                filterAll: true

            },
            {
                Header: "Last updated",
                id: "create_date",
                accessor: (row) => moment(row.create_date, "DD.MM.YYYY HH:mm:ss").format("YYYY.MM.DD HH:mm:ss").toString(),
                width:150,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["create_date"], threshold: matchSorter.rankings.CONTAINS}),
                filterAll: true

            },


            {
                Header: "Expire date",
                id: 'expire_date',
                accessor: (row) => moment(row.expire_date, "DD.MM.YYYY").format("YYYY.MM.DD").toString(),

                width:100,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["expire_date"], threshold: matchSorter.rankings.CONTAINS}),
                filterAll: true


            },

            {
                Header: "License",
                id: 'license_type',
                accessor: d => getLicenseName(d.license_type),
                width:150

            },
            {
                Header: "Action",
                accessor: "id",
                width:80,
                Filter: () => <React.Fragment></React.Fragment>,
                Cell: ({row,value}) => (
                    <div>
                        <UserEdit rowId={value} userId={row.email_user} data={{
                            ...row,
                            license_type: getLicenseId(row.license_type),
                            password: 'defaultPassword',
                            passwordConfirm: 'defaultPassword'
                        }}/>
                        <UserDel rowId={value} userId={row.email_user} />
                    </div>
                )

            }
        ];

    }

    render() {
        const {data} = this.props;
        //console.log(data);
        return (
            <ReactTable
                filterable
                data={data}
                columns={this.columns}
                defaultFilterMethod={(filter, row) => String(row[filter.id] == filter.value)}
                defaultSorted={[
                    {
                        id: "create_date",
                        desc:true
                    }
                ]}
                defaultPageSize={20}
                className="-striped -highlight"/>
        )
    }
}



export default class UserList extends Component {

    constructor(props) {
        super(props);

    }
    componentWillMount() {
        this.setState({
            open : false
        });
    }

    show = () =>  this.setState({ open: true })
    close = () => this.setState({ open: false })

    render() {
        const {data, isAdmin} = this.props;
        console.log(this.props)
        const {open} = this.state;
        return (
            <React.Fragment>

                <Header as='h2'>
                    <Icon name='user'/>
                    <Header.Content>
                        User Management
                    </Header.Content>
                </Header>
                <Button compact onClick={this.show} disabled={!isAdmin}>
                    <Header as='h4'>
                        <Icon.Group size='large'>
                            <Icon name='user'/>
                            <Icon corner name='plus'/>
                        </Icon.Group>
                        Add new user
                    </Header>
                </Button>
                <Divider hidden/>

                <UserAdd open={open} onClose={this.close}
                         initialValues={{
                             expire_date: moment().add(1, 'Y'),
                             license_type: 99
                         }}/>

                <UserTable data={data} />

            </React.Fragment>
        )

    }
}
