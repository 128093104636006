import React, { Component } from 'react'
import { Button , Icon, Form, Message} from 'semantic-ui-react'
import {reduxForm} from "redux-form";

const items = [
    'Collected license information will be stored unto Keldas license server',
    'License request stores information about computer id, mac addresses',
    'Change / upgrades of computer hardware might require a new license request',
    'Generated license key can be downloaded from users account at any time',
  ];


class LicenseConfirm extends Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (values) => {
        console.log(values)
        console.log(this.props.licenseInfo)
        let res = this.props.persistLicenseInfo(this.props.licenseInfo);
        this.props.onClose();
        return res;
    }


    render() {
        const {previousPage, handleSubmit} = this.props;

        return (
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <Message info>
                    <Message.Header>Heads up!</Message.Header>
                    <Message.List items={items} />
                </Message>
                <Button icon="left arrow" labelPosition="left" content="Previous" onClick={previousPage} type='button'/>

                <Button icon labelPosition="right" type="submit" >
                    <Icon name='write'/>
                    Save license
                 </Button>
            </Form>
        )
    }
}


export default reduxForm({
    form: 'licenseConfirm',

})(LicenseConfirm);
