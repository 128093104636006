import React, { Component } from 'react'
import { Link } from 'react-router-dom'


export default class Logout extends Component {

    componentWillMount() {
        this.props.logOutUser()
    }


    render() {
        return <div>
            Logged out of session. <Link to='login'> Login again.</Link>
        </div>
    }
};
