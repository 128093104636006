import {change, reduxForm} from 'redux-form'
import { connect } from 'react-redux'
import LicenseReview from '../../components/License/LicenseReview'
import {signLicenseRequest, previousLicensePage, nextLicensePage} from "../../actions/index";


const mapStateToProps = (state, ownProps) => {
    return {
        // request: state.license.request,
        // expireDate: state.license.expireDate
        initialValues: state.license,

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {


        /*  signLicense: (expireDate, licenseTypes, licenseRequest) => {
              return dispatch(signLicenseRequest(expireDate, licenseTypes, licenseRequest))
          },
          updateLicenseRequest : (request) => {
              dispatch(change("license", "licenseHolder", request.licenseHolder))
              dispatch(change("license", "licenseCertificate", request.licenseCertificate))
          },*/
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LicenseReview);