import { connect } from 'react-redux'
import UserAdd from '../../components/User/UserAdd'
import {addUser} from "../../actions/index";


const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addUser: (data) => {
            return dispatch(addUser(data));
        }
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(UserAdd);
