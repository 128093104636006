import React, { Component } from 'react'
import {Field, reduxForm, SubmissionError} from 'redux-form'
import { Button, Form, Grid, Header, Icon, Image, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


const renderField = ({ input, label, type, icon, meta: { touched, error } }) => (
    <React.Fragment>
        <Form.Input fluid {...input} placeholder={label} iconPosition='left' type={type} icon={icon}/>
        {touched && error && <span>{error}</span>}
    </React.Fragment> // Avoid extra div
);

class Login extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    submit = (values) => {
        return this.props.logInUser(values.username, values.password, this.props.history); //(.then((result) => {

       /* }).catch((error) => {
            console.log(error);
            throw new SubmissionError({
                _error:'Login failed'
            })
        });*/
    }

    render() {
        const { error, handleSubmit, pristine, reset, submitting } = this.props
        return (
            <div className='login-form'>
                {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
                <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
            <Grid
                textAlign='center'
                style={{height : '100%'}}
                verticalAlign='middle'
                >
                <Grid.Column style={{maxWidth: 450}}>
                    <Header as='h1' textAlign='center'>
                        <Icon name='key'/>
                        Kelda License Management
                    </Header>
                    <Header as='h3' textAlign='center'>
                        {' '} Log-in to your account
                    </Header>
                    <Form size='large' onSubmit={handleSubmit(this.submit)}>
                        <Segment stacked>
                            <Field
                                name='username'
                                icon='user'
                                placeholder='E-mail address'
                                component={renderField}
                            />
                            <Field
                                name='password'
                                icon='lock'
                                placeholder='Password'
                                component={renderField}
                                type='password'
                            />
                            {error && <strong>{error}</strong>}
                            <Button fluid size='large' disabled={pristine || submitting} type="submit">Login</Button>
                        </Segment>
                    </Form>
                    <Message>
                        Forgot password?  <Link to='/reset'>Reset your account</Link>
                    </Message>
                </Grid.Column>
            </Grid>
            </div>
        )
    }

};


export default reduxForm({
    form: 'loginValidation'
})(Login);
