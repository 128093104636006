import { connect } from 'react-redux'
import LicenseManagement from '../../components/License/LicenseManagement'
import {getAllLicenses} from "../../actions/index";


const mapStateToProps = state => {
    return {
        userName: state.auth.username,
        data: state.license.allLicenses
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAllLicenses : () => {
            return dispatch(getAllLicenses());
        }

    }

};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(LicenseManagement);
