import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import LicenseConfirm from '../../components/License/LicenseConfirm'
import {persistLicenseInfo} from '../../actions'


const mapStateToProps = (state, ownProps) => {
    return {
        licenseInfo: state.license
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
        return {
            persistLicenseInfo: (data) =>  dispatch(persistLicenseInfo(data))

        }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LicenseConfirm);

/*
    reduxForm({
        form: 'license',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true

        // enableReinitialize: true
*/

