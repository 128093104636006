import React, { Component } from 'react'
import {Field, reduxForm, SubmissionError} from 'redux-form'
import { Button, Form, Grid, Header, Icon, Image, Message, Segment, Label } from 'semantic-ui-react'
import {requiredField, validateEmail} from '../../common/clientValidators'


const renderField = ({ input, label, type, icon, meta: { touched, error }, ...rest }) => (
    <React.Fragment>
        <Form.Input {...input} {...rest} iconPosition='left' type={type} icon={icon}/>
        {touched && error && <Label pointing basic color='red' >{error}</Label>}
    </React.Fragment> // Avoid extra div
);




const validate = values => {
    const errors = {}
    if (values.password && values.passwordConfirm) {
        if (values.password !== values.passwordConfirm) errors.password = 'Passwords does not match';
    }
    return errors;
}


class LoginNewPassword extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }


    submit = (values) => {

        return this.props.newPassword(this.props.match.params.token,
            values.username,
            values.password,
            this.props.history);
    }

    render() {
        const { error, handleSubmit, pristine, reset, submitting } = this.props

        return (
            <div className='reset-form'>
                {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
                <style>{`
      body > div,
      body > div > div,
      body > div > div > div.reset-form {
        height: 100%;
      }
    `}</style>
                <Grid
                    textAlign='center'
                    style={{height : '100%'}}
                    verticalAlign='middle'
                >
                    <Grid.Column style={{maxWidth: 450}}>
                        <Header as='h1' textAlign='center'>
                            <Icon name='key'/>
                            Kelda License Management
                        </Header>
                        <Header as='h3' textAlign='center'>
                            {' '} Reset to your account
                        </Header>
                        <Form size='large' onSubmit={handleSubmit(this.submit)}>
                            <Segment stacked>
                                <Field
                                    name='username'
                                    icon='user'
                                    placeholder='E-mail address'
                                    component={renderField}
                                    validate={[requiredField, validateEmail]}
                                />
                                <Field
                                    name='password'
                                    icon='key'
                                    type='password'
                                    placeholder='New password'
                                    component={renderField}
                                    validate={[requiredField]}
                                />

                                <Field
                                    name='passwordConfirm'
                                    icon='key'
                                    type='password'
                                    placeholder='Confirm password'
                                    component={renderField}
                                    validate={[requiredField]}
                                />

                                {error && <strong>{error}</strong>}
                                <Button fluid size='large' disabled={pristine || submitting} type="submit">Reset password</Button>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        )

    }
}

export default reduxForm({
    form: 'confirmValidation',
    validate
})(LoginNewPassword);