import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import LicensePortalApp from './reducers';

import 'semantic-ui-css/semantic.min.css'

import App from './App';
import registerServiceWorker from './registerServiceWorker';

let store = createStore(LicensePortalApp,
    applyMiddleware(thunk));


ReactDOM.render(
    <Provider store={store} >
       <App />
    </Provider>, document.getElementById('root')
);
registerServiceWorker();

