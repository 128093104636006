import React, { Component } from 'react'

import {
    TextArea,
    Form,
    Button,
    Dropdown,
    Label,
    Grid,
    Segment,
    Icon,
    Accordion,
    Checkbox,
    GridColumn
} from "semantic-ui-react";
import { Field, reduxForm} from 'redux-form'

import moment from 'moment';
import axios from 'axios'
//import licenseTypes from '../../common/licenseHelper'

import 'react-datepicker/dist/react-datepicker.css';
import {requiredField, validateEmail} from "../../common/clientValidators";

import KDatePicker from '../KDatePicker'
import KDropDown from '../KDropDown'

const required = value => (value ? undefined : 'Required field');
const requiredList = array => (array && array.length > 0 ? undefined: 'Missing license type');

const requestLength = value => value.trim().length >= 578 ? undefined : 'Invalid length of license request  ';
const requestStart = value => value.trim().startsWith("----- BEGIN LICENSE REQUEST ----") ? undefined : "Missing proper ----- BEGIN .... header ";
const requestEnd = value => value.trim().endsWith("------ END LICENSE REQUEST -----") ? undefined : "Missing proper ------ END .... tail ";

const convertDate = (date) => {
    return date instanceof moment ? date.format('DD.MM.YYYY') : date;
};





const CustomTextArea = ({label, input, placeHolder, rows, width, meta : {touched, error}, ...rest}) =>
    <div>{touched && error && <Label basic color='red' pointing='below'>{error}</Label>}
        <Form.Field {...input} label={label} control={TextArea} placeholder={placeHolder} rows={rows}
                    style={{fontFamily: 'Monospace'}} {...rest}/>

    </div>
;


const RenderInputField = ({input,  label, width, meta: { touched, error }, ...rest}) =>
    <React.Fragment>
        <Form.Field width={width}>
            <label>{label}</label>
            <input {...input} {...rest} />
            {touched && error && <Label  basic color='red' pointing>{error}</Label>}
        </Form.Field>
    </React.Fragment>



const RenderStatus = ({input,  label, width, meta: { touched, error }, ...rest}) =>
    <React.Fragment>
        <Icon name="warning"></Icon>
        <label>{label}</label>
    </React.Fragment>

function CustomCheckBox({input, ...rest}) {
    console.log(input);
    return <Checkbox checked={input.value} {...input} {...rest}></Checkbox>
}
class LicenseInfo extends Component {
    constructor(props) {
        super(props);

    }


    onValidateRequest = (e) => {
        e.preventDefault();
        console.log("OnValidateRequest");
    }

    handleSubmit = (data) => {
        console.log(data);

        return this.props.signLicense(convertDate(data.expire_date),
            data.license_types,
            data.licenseRequest,
            data.fullName,
            data.email,
            data.companyName)
    }
    render() {
        const { error, handleSubmit, productCodes,initialValues } = this.props;
        console.log(error);
        console.log(this.props);
        console.log(initialValues);
        //licenseTypes

         return (
            <Form onSubmit={handleSubmit(this.handleSubmit)} >
                <Grid padded>
                    <Grid.Row columns={1} centered>
                        <Grid.Column>
                            {/*<Form.Group width={2} >*/}
                            {/*    <Field name='license_format' label="Generate old license format" component={CustomCheckBox}*/}
                            {/*           onChange={this.props.toggleLicenseTypes}/>*/}

                            {/*</Form.Group>*/}


                            <Form.Group >
                                <Field name='expire_date' label="Expire date:" component={KDatePicker}
                                       minDate={moment()} validate={[requiredField]} placeholder='Expire date' width={8}/>

                                <Field name='license_types' label='Select Licenses: ' component={KDropDown}
                                       multiple search selection options={productCodes}
                                       placeholder='Select licenses' validate={[requiredList]} width={8}/>
                            </Form.Group>

                            <Form.Group>
                                <Field name='fullName' label="Name:" component={RenderInputField}
                                       validate={[requiredField]} placeholder='Enter licensee name' width={8}/>

                                <Field name='email' label='Email: ' component={RenderInputField}
                                       placeholder='Enter email' validate={[requiredField, validateEmail]} width={8}/>

                            </Form.Group>

                            <Form.Group>
                                <Field name='companyName' label="Company name:" component={RenderInputField}
                                        placeholder='Company name'  width={16}/>

                            </Form.Group>

                            <Form.Group>
                                <Field name='licenseRequest' component={CustomTextArea} label='License request:'
                                       placeHolder='Paste in your license request here' rows={10} width={16}
                                       validate={[required, requestLength, requestStart, requestEnd]} style={{width: "320px", fontFamily: 'Monospace'}}/>
                                { error ? <div className="ui negative message">
                                    <div className="header">
                                        Error
                                    </div>
                                    <p>{error}</p>
                                </div> : null}

                                {/*<Grid>*/}
                                {/*    <Grid.Row>*/}
                                {/*        <Grid.Column  width={9}>*/}
                                {/*        </Grid.Column>*/}
                                {/*        <Grid.Column width={7}>*/}
                                {/*            { !this.props.invalid ? <React.Fragment>*/}
                                {/*                <div style={{height: "22px"}}/>*/}
                                {/*                <Button onClick={(e) => this.onValidateRequest(e)}>*/}
                                {/*                    Validate license*/}
                                {/*                </Button>*/}
                                {/*                <Segment secondary>*/}
                                {/*                    License request is valid.*/}
                                {/*                </Segment>*/}
                                {/*                <Segment inverted color="red">*/}
                                {/*                    You are using an old license format. Please consider to upgrade your software to a newer version.*/}
                                {/*                </Segment>*/}
                                {/*            </React.Fragment> : null}*/}
                                {/*         </Grid.Column>*/}
                                {/*    </Grid.Row>*/}
                                {/*</Grid>*/}
                            </Form.Group>


                        </Grid.Column>



                    </Grid.Row>
                    <Grid.Row columns={1} centered >
                        <Form.Button icon="right arrow" type="submit" labelPosition="right" content='Next' disabled={this.props.invalid} />
                    </Grid.Row>
                </Grid>
            </Form>

        )
    }


}


export default reduxForm({
    form: 'licenseInfo',
    enableReinitialize : true, // this is needed!!

})(LicenseInfo);




