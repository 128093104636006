import { connect } from 'react-redux'
import LicenseKey from '../../components/License/LicenseKey'
import {getLicenseKey} from '../../actions'

const mapStateToProps = state => {
    return {
        licenseKey: state.license.activeLicense.licenseKey,
        machineId: state.license.activeLicense.machineId,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLicenseKey: (id) => {
            return dispatch(getLicenseKey(id));
        }
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(LicenseKey);









