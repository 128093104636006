import React, { Component } from 'react'
import { Header, Icon , Button, Divider, Modal} from 'semantic-ui-react'
import "react-table/react-table.css";
import UserAdd from '../../containers/User/UserAdd';
import UserList from './UserList'




class UserManagement extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getAllUsers();
    }
    render() {
        return (
            <React.Fragment>
                {{ // Mimic switch
                    listUsers: (<UserList data={this.props.data} isAdmin={this.props.isAdmin}/>),
                    addUser: (<UserAdd appearance='add'/>)
                }[this.props.type]}
            </React.Fragment>
        )
    }
}

export default UserManagement
