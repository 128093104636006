import { connect } from 'react-redux'
import LicenseDel from '../../components/License/LicenseDel'
import {delLicense} from "../../actions/index";


const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        delLicense: (id) => {
            return dispatch(delLicense(id));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(LicenseDel);









