import { connect } from 'react-redux'
import Login from '../../components/Login/Login'
import {getAdminInfo, logInUser} from '../../actions/index'



const mapStateToProps = state => {
    return {
        userName: state.auth.username
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAdminInfo : () => {
            return dispatch(getAdminInfo());
        },

        logInUser : (email, password, history) => {
            return dispatch(logInUser({email, password, history}));
        }

    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps

)(Login);
