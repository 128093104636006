import React, {Component} from "react";
import { Header, Icon, Modal, Divider, Form, Label, Container, Select, Button, Segment, Message} from 'semantic-ui-react'
import KDatePicker from '../KDatePicker'
import KDropDown from '../KDropDown'
import UserForm from './UserForm'
import {requiredField, validateEmail} from '../../common/clientValidators'

import moment from 'moment';

import {Field, reduxForm} from 'redux-form'
import licenseTypesOldFormat from "../../common/licenseHelper";


const validate = values => {
    const errors = {}
    if (values.password && values.passwordConfirm) {
        if (values.password !== values.passwordConfirm) errors.password = 'Passwords does not match';
    }
    return errors;
}


const RenderInputField = ({input,  label, meta: { touched, error }, ...rest}) =>
    <Form.Field>
        <label>{label}</label>
        <input {...input} {...rest} />
         {touched && error && <Label  basic color='red' pointing>{error}</Label>}
    </Form.Field>


const convertDate = (date) => {
    return date instanceof moment ? date.format('DD.MM.YYYY') : date;
};

class UserAdd extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentWillMount() {
        this.setState({
            canSave: false,
            open: false
        })

    }

    submit = (values) => {
        const normalized_values = {
            ...values, expireDate : convertDate(values.expireDate)
        };

        return this.props.addUser(normalized_values).then(() => this.props.onClose()); //(.then((result) => {

    }

    onOpen = () => {

    }

    onClick = ()  => {
        this.refs.editForm.submit(this.props.onSubmit) // Submit form values
        this.setState(
            { open: false }
        )
    }


    updateValidationBtnState = (canSave) => {
        this.setState({
            canSave: canSave
        })
    };


    render() {
        const {  open, error, handleSubmit, pristine, submitting, initialValues, onClose} = this.props
        const { canSave } = this.state;
        return <div>
            <Modal size='small' open={open} dimmer='inverted' >
                <Modal.Header>
                    <Header as='h4'>
                        <Icon.Group size='large'>
                            <Icon name='user'/>
                            <Icon corner name='write'/>
                        </Icon.Group>
                        Add user data
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    <UserForm ref='editForm' onSubmit={this.submit.bind(this)}
                              updateValidationState={this.updateValidationBtnState} initialValues={initialValues}/>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.onClick()} disabled={canSave}>
                        <Icon name='write'/>Save user
                    </Button>
                    <Button onClick={() => onClose()}>
                        <Icon name='user cancel' />Cancel
                    </Button>
                </Modal.Actions>
            </Modal>

        </div>
    }
}

export default reduxForm({
    form: 'userAddValidation',
    validate

})(UserAdd);
