import axios from 'axios'
import dotenv from 'dotenv'
import {SubmissionError} from 'redux-form'
import licenseTypesOldFormat, {setProductCodes} from '../common/licenseHelper'
import _ from 'lodash';
import moment from "moment";
import {bindActionCreators} from "redux";

const ROOT_URL = process.env.REACT_APP_BACKEND_URL;

const axiosApiInstance = axios.create();


const getAccessToken = () => {
    return localStorage.getItem('accessToken')
}

const getRefreshToken = () => {
    return localStorage.getItem('refreshToken')
}

const updateTokens = ({accessToken, refreshToken = null}) => {
    localStorage.setItem('accessToken', accessToken)
    if (refreshToken !== null) localStorage.setItem('refreshToken', refreshToken)
}


axiosApiInstance.interceptors.request.use(
    async config => {
        //const accessToken = localStorage.getItem('accessToken')
        const accessToken = getAccessToken()
        config.headers = {
            'Authorization': `${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        //console.log(config)
        return config;
    },
    (error) => {
        console.log(error)
        return Promise.reject(error)
    }

)

axiosApiInstance.interceptors.response.use((response) => {
        return response
    }, async (error) => {
        const config = error?.config
        console.log(error)

        if (error?.response?.status === 401 && !config.sent) {
            // debugger
            config.sent = true

            // Update access token
            const accessToken = await refreshAccessToken();
            updateTokens({accessToken: `JWT ${accessToken}`})

            // Call original function
            return axiosApiInstance(config);

        }
        return Promise.reject(error)
    }
)


export const getLicenseKey = (licenseId) => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.post(`${ROOT_URL}/get-license-key/`,
                {
                    licenseId: licenseId
                })
            return dispatch(setActiveLicenseKey(response.data))

        } catch (error) {
            console.log(error)
        }

    }

}

const refreshAccessToken = async () => {
    try {

        axios.defaults.headers.common['Authorization'] = getRefreshToken();
        const response = await axios.post(`${ROOT_URL}/refresh` )
        return response.data.access_token
    }

    catch (error) {
        console.log(error)
    }


}



function updateLicenseCodes(data) {
    const l = data.map(x => ({
            key: parseInt(x[0]), text: x[1], value: parseInt(x[0])}));

    const licenseTypes = _.sortBy(l, 'text');
    return {
        type: 'UPDATE_PRODUCT_CODES',
        payload: licenseTypes
    };
}

export const getProductCodes = () => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.get(`${ROOT_URL}/license-codes/`)
            console.log(response)
            setProductCodes(response.data);
            return dispatch(updateLicenseCodes(response.data))
        } catch (err) {
            console.log(err);
        }
    }
}


export const getLicenseReq = (licenseId) => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.post(`${ROOT_URL}/get-license-req/`, {
                licenseId: licenseId
            })
            return dispatch(setActiveLicenseReq(response.data))
        } catch (error) {
            console.log(error)

        }
    }

}


export const setActiveLicenseKey = ({licenseKey, machineId}) => {
    return {
        type: 'UPDATE_ACTIVE_LICENSE_KEY',
        payload: {
            licenseKey: licenseKey === null ? '': licenseKey,
            machineId: machineId === null ? '': machineId
        }
    }
}

export const setActiveLicenseReq = ({licenseReq}) => {
    return {
        type: 'UPDATE_ACTIVE_LICENSE_REQ',
        payload: {
            licenseReq: licenseReq === null ? '': licenseReq
        }
    }
}





export const updateExpireDate = (date) => {
    return {
        type: 'UPDATE_EXPIRE_DATE',
        payload: date
    }
};

export const nextLicensePage = () => {
    return {
        type: 'NEXT_LICENSE_PAGE',
        payload: {}
    }
}
export const previousLicensePage = () => {
    return {
        type: 'PREVIOUS_LICENSE_PAGE',
        payload: {}
    }
}

export const initializeLicenseWizard = () => {
    console.log('initializeLicenseWizard')
    return {
        type: 'INIT_LICENSE_WIZARD',
        payload: {
            expire_date: moment().add(1, 'Y'),
            license_types: [99]
        }
    }
}

export const toggleLicenseTypes = () => {
    return {
        type: 'TOGGLE_LICENSE_TYPES'
    }
}



export const persistLicenseInfo = (data) => {
    return async (dispatch) => {
        try {
            const _ = await axiosApiInstance.post(`${ROOT_URL}/persist-license/`, {
                expireDate: data.expire_date,
                licenseType: data.license_types,
                licenseRequest: data.licenseRequest,
                licenseKey: data.licenseKey,
                licenseHolder: data.licenseHolder,
                computerId: data.computerId,
                origin: 'LicensePortal',
                companyName: data.companyName,
                fullName: data.fullName,
                email: data.email

            })

            return dispatch(getAllLicenses())
        } catch (error) {
            console.log(error)
            throw new SubmissionError({
                _error: error.message
            });
        }

    }


}

export const updateLicenseRequest = (request) => {
    return {
        type: 'UPDATE_LICENSE_REQUEST',
        payload: request
    }
};


export const storeLicenseInfo = (licenseHolder, computerId, licenseRequest, licenseKey,
                                 expire_date, license_types, fullName, email, companyName, licenseFormat) => {
    return {
        type: 'UPDATE_LICENSE_REQUEST',
        payload: {
            expire_date: expire_date,
            license_types: license_types,
            licenseHolder: licenseHolder,
            licenseRequest: licenseRequest,
            licenseKey: licenseKey,
            computerId: computerId,
            fullName: fullName,
            email: email,
            companyName: companyName,
            licenseFormat: licenseFormat

        }
    }
}
export const signLicenseRequest = (expireDate, licenseTypes, licenseRequest, fullName, email, companyName) => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.post(`${ROOT_URL}/sign-license/`, {
                'expire-date': expireDate,
                'license-type': licenseTypes,
                'license-request': licenseRequest,
                'email': email,
                'fullname': fullName,
                extra: 'skip-save',
                origin: 'web-portal'

            })
            console.log(response.data);
            await dispatch(storeLicenseInfo(
                response.data['license-holder'],
                response.data['computerId'],
                licenseRequest, response.data['signed-license'],
                expireDate, licenseTypes,
                fullName, email, companyName,
                response.data['ErrorMsg'] == 'NoError' ? false : true))
            return dispatch(nextLicensePage())
        } catch (error) {
            console.log(error.response);
            throw new SubmissionError({
                _error: error.response.data.message
            });
        }

    }
}


export const logInUser = ({email, password, history}) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${ROOT_URL}/auth`, {username: email, password: password});

/*
            const accessToken = 'JWT ' + response.data.access_token;
            const refreshToken = 'JWT ' + response.data.refresh_token;
*/

            const accessToken = `JWT ${response.data.access_token}`;

            updateTokens(
                {
                    accessToken: accessToken, refreshToken: `JWT ${response.data.refresh_token}`
                })

            dispatch({type: 'AUTH_USER', payload: {username: email, token: accessToken}});


            await dispatch(getProductCodes());
            history.push('/');
            return dispatch(getAdminInfo());
        } catch (error) {
            console.log(error)
            throw new SubmissionError({
                _error:'Login failed.'
            })
        }

    }
};





export const resetAccount = (email) => {
    return async (dispatch) => {
        try {
            await axiosApiInstance.post(`${ROOT_URL}/reset_password/`, {email_admin: email})
        } catch (error) {
            throw new SubmissionError({
                _error:'Reset failed.'
            })

        }

    }
}


export const newPassword = (confirmToken, email, password, history) => {
    return async (dispatch) => {
        try {
            const accessToken = getAccessToken();
            if (accessToken)

            await axiosApiInstance.post(`${ROOT_URL}/confirm/`,  {
                confirmToken: confirmToken,
                email: email,
                password: password
            })

            await history.push('/')

        } catch (error) {
            console.log(error)
            throw new SubmissionError({
                _error:'Password confirmation failed'
            })

        }

    }
}


export const logOutUser = () => {
    return (dispatch) => {
        dispatch({type: 'LOG_OUT', payload: {}})
    }
}

export const delUser = (email) => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.post(`${ROOT_URL}/del-user/`,  {
                email_user: email
            })
            if (response.data) return dispatch(getAllUsers())
        }
        catch (error) {
            console.log(error)
            throw new SubmissionError({
                _error: 'Deleting user ' + email + 'failed'
            });

        }

    //)
    }
}


export const getAdminInfo = () => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.get(`${ROOT_URL}/is-admin/`)
            return dispatch({type: 'STORE_ADMIN_INFO', payload: {admin: response.data}})
        } catch (e) {
            throw new SubmissionError({
                _error:'Unknown error.'
            })
        }

        //
        // axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        // return axios.get(`${ROOT_URL}/is-admin/`)
        //     .then(response => {
        //         return dispatch({type: 'STORE_ADMIN_INFO', payload: {admin: response.data}})
        //     }).catch(() => {
        //         // For submissionError, make sure that a promise is returned (see return)
        //         throw new SubmissionError({
        //             _error:'Unknown error.'
        //         })
        //     })
    }
};



export const updateUser = (data) => {
    return async (dispatch) => {

        try {
            const response = await axiosApiInstance.post(`${ROOT_URL}/update-user/`, data)
            if (response.data) return dispatch(getAllUsers())
        } catch (error) {
            throw new SubmissionError({
                _error: 'Updating user ' + data.email + 'failed'
            });

        }

        // axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        // return axios.post(`${ROOT_URL}/update-user/`, data)
        //     .then(response => {
        //         if (response.data) {
        //             return dispatch(getAllUsers())
        //         } else {
        //             throw new SubmissionError({
        //                 _error: 'Updating user ' + data.email + 'failed'
        //             });
        //         }
        //
        //     }).catch((error) => {
        //         console.log(error);
        //         logOutUser();
        //             if (error.name === 'SubmissionError') throw new SubmissionError({
        //                 _error: error.errors._error
        //             })
        //
        //         }
        //     )
    }
}


export const addUser = (data) => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.post(`${ROOT_URL}/check-user/`, {email_user: data.email_user})
            if (!response.data) {
                await axiosApiInstance.post(`${ROOT_URL}/add-user/`, {...data})
                return dispatch(getAllUsers())
            } else throw new SubmissionError({
                _error: 'Email ' + data.email_user + ' is already registered!'
            });
        } catch (error) {
            if (error.name === 'SubmissionError') throw new SubmissionError({
                _error: error.errors._error
            })
        }
        /*   axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
           return axios.post(`${ROOT_URL}/check-user/`, {email_user: data.email_user})
               .then(response => {
                   if (!response.data) {
                       return axios.post(`${ROOT_URL}/add-user/`,
                           {
                               ...data
                           }).then(response => {
                           return true;
                       }).then(response => {
                          return dispatch(getAllUsers())
                       }).catch((error) => {
                           throw new SubmissionError({
                               _error: 'addUser failed!'
                           })
                       })
                   } else {
                       throw new SubmissionError({
                           _error: 'Email ' + data.email_user + ' is already registered!'
                       });
                   }
               }).catch((error) => {
                   if (error.name === 'SubmissionError') throw new SubmissionError({
                       _error: error.errors._error
                   })
                   console.log(error)
                   throw new SubmissionError({
                       _error:'Server error'
                   })
               })*/

    }
}


export const getAllUsers = () => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.get(`${ROOT_URL}/view-all-users-new/`)
            console.log(response)
            dispatch({type: 'STORE_USERS', payload: {allUsers: response.data}});
        } catch (error) {
            console.log(error)
        }

        //axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        // return axios.get(`${ROOT_URL}/view-all-users-new/`)
        //     .then(response => {
        //         console.log(response.data);
        //         dispatch({type: 'STORE_USERS', payload: {allUsers: response.data}});
        //     }).catch((error) => {
        //
        //     })
    }
}


export const delLicense = (id) => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.post(`${ROOT_URL}/del-license/`, {id: id})
            if (response.data) {
                return dispatch(getAllLicenses())
            } else throw new SubmissionError({
                    _error: 'Deleting license ' + id + 'failed'
                });

        } catch (error) {
            console.log(error);
            if (error.name === 'SubmissionError') throw new SubmissionError({
                _error: error.errors._error
            })

        }
    }
}



export const getAllLicenses = () => {
    return async (dispatch) => {
        try {
            const response = await axiosApiInstance.get(`${ROOT_URL}/view-all-licenses/`)
            console.log(response)
            dispatch({type: 'STORE_LICENSES', payload: {allLicenses: response.data}});
        } catch (error) {
            console.log(error)
        }
        // axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        // return axios.get(`${ROOT_URL}/view-all-licenses/`)
        //     .then(response => {
        //         dispatch({type: 'STORE_LICENSES', payload: {allLicenses: response.data}});
        //     }).catch((error) => {
        //         console.log(error)
        //     })
    }
}



