import React, { Component } from 'react'
import {Field, reduxForm, SubmissionError} from 'redux-form'
import { Button, Form, Grid, Header, Icon, Image, Message, Segment } from 'semantic-ui-react'

const renderField = ({ input, label, type, icon, meta: { touched, error } }) => (
    <React.Fragment>
        <Form.Input fluid {...input} placeholder={label} iconPosition='left' type={type} icon={icon}/>
        {touched && error && <span>{error}</span>}
    </React.Fragment> // Avoid extra div
);


class LoginReset extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            hidden : true,
            message : ''
        })

    }


    submit = (values) => {
        this.setState({
            hidden: false,
            message: 'An email has been sent to ' + values.username
        })

        this.props.resetAccount(values.username);
    }

    render() {
        const { error, handleSubmit, pristine, reset, submitting } = this.props
        const { hidden, message} = this.state;

        return (
        <div className='reset-form'>
            {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
            <style>{`
      body > div,
      body > div > div,
      body > div > div > div.reset-form {
        height: 100%;
      }
    `}</style>
            <Grid
                textAlign='center'
                style={{height : '100%'}}
                verticalAlign='middle'
            >
                <Grid.Column style={{maxWidth: 450}}>
                    <Header as='h1' textAlign='center'>
                        <Icon name='key'/>
                        Kelda License Management
                    </Header>
                    <Header as='h3' textAlign='center'>
                        {' '} Reset to your account
                    </Header>
                    <Form size='large' onSubmit={handleSubmit(this.submit)}>
                        <Segment stacked>
                            <Field
                                name='username'
                                icon='user'
                                placeholder='E-mail address'
                                component={renderField}
                            />
                            {error && <strong>{error}</strong>}
                            <Button fluid size='large' disabled={pristine || submitting} type="submit">Reset password</Button>
                        </Segment>
                    </Form>
                    <Message hidden={hidden}>{message}</Message>
                </Grid.Column>
            </Grid>
        </div>
        )

    }
}

export default reduxForm({
    form: 'resetValidation'
})(LoginReset);