import { connect } from 'react-redux'

import LicenseWizard from '../../components/License/LicenseWizard'
import {nextLicensePage, previousLicensePage} from '../../actions'


const mapStateToProps = (state, ownProps) => {
    return {
        // request: state.license.request,
        // expireDate: state.license.expireDate
        page: state.license.currentPage
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

        nextPage : () => {
            dispatch(nextLicensePage());
        },

        previousPage: () => {
            dispatch(previousLicensePage())
        }

        /*  signLicense: (expireDate, licenseTypes, licenseRequest) => {
              return dispatch(signLicenseRequest(expireDate, licenseTypes, licenseRequest))
          },
          updateLicenseRequest : (request) => {
              dispatch(change("license", "licenseHolder", request.licenseHolder))
              dispatch(change("license", "licenseCertificate", request.licenseCertificate))
          },*/
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LicenseWizard);